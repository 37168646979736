import { Link } from 'react-router-dom';
import './Header.scss';
import { useContext } from 'react';
import { GlobalDataContext } from '../../utils/GlobalValues';
const Header = () => {
    const globalData = useContext(GlobalDataContext);
    return (
        <div className="header | center pad-m">
            <Link to="/">
                <h5 className="center text-shadow">{globalData.value.navbarTitle}</h5>
            </Link>
        </div>
    )
}

export default Header