import { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import './Gallery.scss';
import { TiArrowRightThick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import { Work } from '../../../types/Types';
import { getAllWorks, getWorksFromType } from '../../../api/apiFunctions';
import { GlobalDataContext } from '../../../utils/GlobalValues';

interface GalleryProps {
    activeType: string;
    rowCount?: {
        bigScreen: number;
        mediumScreen: number;
        smallScreen: number;
    }
}

const Gallery = (props: GalleryProps) => {
    //_________VARIABLES_________
    const rowCountArray = props.rowCount || {bigScreen: 3, mediumScreen:2, smallScreen:1};
    const imageWidth = 512;
    const [rowCount, setRowCount] = useState<number>(rowCountArray.bigScreen);
    const [galleryWorks, setGalleryWorks] = useState<Work[]>([]);
    const [galleryWorksRows, setgalleryWorksRows] = useState<Work[][]>([]);
    const mediumScreen = useMediaQuery('(min-width: 799.99px)');
    const bigScreen = useMediaQuery('(min-width: 1500px)');
    const globalData = useContext(GlobalDataContext);

    //____________FUNCTIONS__________


    function calculateRowCount(): number {
        if (mediumScreen) {
            if (bigScreen) {
                return rowCountArray.bigScreen;
            }
            return rowCountArray.mediumScreen;
        }
        return rowCountArray.smallScreen;
    }

    function getImageRowHeight(row: Work[]): number {
        let rowHeight = 0;

        row.forEach((work: Work) => {
            const img = work.thumbnail;
            rowHeight += (imageWidth / img.width) * img.height;
        })

        return rowHeight;
    }

    function getImageRowsFromImages(images: Work[], numberOfRows: number) {
        let imageRows: Work[][] = [];
        for (let index = 0; index < numberOfRows; index++) {
            imageRows.push([]);
        }

        images.forEach((work: Work) => {
            imageRows.sort(function (a, b) {
                if (getImageRowHeight(a) > getImageRowHeight(b)) {
                    return 1;
                }
                if (getImageRowHeight(a) < getImageRowHeight(b)) {
                    return -1;
                }
                return 0;
            })[0].push(work);
        })

        return imageRows;
    }

    //_________HOOKS_________
    useEffect(() => {
        if (props.activeType.toUpperCase() === "TODOS" || props.activeType.toUpperCase() === "TODO" || props.activeType === "") {
            getAllWorks(setGalleryWorks);
        } else {
            getWorksFromType(props.activeType, setGalleryWorks)
        }
    }, [props.activeType]);

    useEffect(() => {
        setRowCount(calculateRowCount);
    }, [mediumScreen, bigScreen]);

    useEffect(() => {
        setgalleryWorksRows(getImageRowsFromImages(galleryWorks, galleryWorks.length < rowCount ? galleryWorks.length : rowCount));
    }, [galleryWorks, rowCount]);

    //_________COMPONENT_________
    return galleryWorks.length > 0 ? (
        <div className="gallery">
            {galleryWorksRows &&
                <div className="gallery__wrapper | gap-s " style={{ gridTemplateColumns: `repeat(${rowCount}, 1fr)` }}>
                    {galleryWorksRows && galleryWorksRows.map((imageRow: Work[], i: number) => (
                        <div key={"row" + i} className='flex-column | gap-s'>
                            {imageRow.map((work: Work, j: number) => (
                                <Link to={`/portfolio/${work.id}`}>
                                    <div className='img-parent'>
                                        <img className='shadow roundborder' key={"img" + j} src={work.thumbnail.url + `?w=${imageWidth}&fm=webp&q=100`}></img>
                                        <div className="img-overlay | flex-column center gap-xs | roundborder text-white">
                                            <h5>{work.title}</h5>
                                            <div className="button-white pad-xxs2">{globalData.value.portfolioSeemore} <TiArrowRightThick style={{ height: "18px" }} /></div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>}
        </div>
    ) : <div className="white-bg | pad-xxl" >No hay contenido que mostrar (por el momento).</div>
}

export default Gallery