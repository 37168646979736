import { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import WorkDetails from '../Portfolio/Work/WorkDetails';
import './Videos.scss';
import { GlobalDataContext } from '../../utils/GlobalValues';
import Contact from '../../components/contact/Contact';
import { Video } from '../../types/Types';
import { getAllVideos } from '../../api/apiFunctions';
import { textToParagraphs } from '../../utils/Functions';

const Videos = () => {
    const globalData = useContext(GlobalDataContext);
    const [videos, setVideos] = useState<Video[]>();
    const [featuredVideo, setFeaturedVideo] = useState<Video>();

    useEffect(() => {
        getAllVideos(setVideos);
        setFeaturedVideo(videos?.find(video => video.featured));
        console.log(featuredVideo);
      }, []);

    return <div>
        <div className="welcome | flex-column center-x gap-s | text-white textshadow bg-img8">
            <Header />
<h2 className='bold'>{`${globalData.value.videosFeaturedTitle.toUpperCase()}: ${featuredVideo?.title}`}</h2>
            <div className="featured-video-block">
                <iframe className="shadow roundborders" src={featuredVideo?.url} frameBorder={0} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                <div className="featured-video-block__texts | flex-column pad-xs gap-xxs">
                {textToParagraphs(featuredVideo?.description || "")}
                </div>

            </div>

        </div>
        <div className="videos-wrapper pad-l bg-img9 flex-column center">
            <h2 className='bold pad-xxxs uppercase' >{globalData.value.allVideosTitle}</h2>
            <div className="videos vpad-m gap-m">
                {videos?.map(video => {
                    return <div className="videos__card | bg-white shadow" data-state={video.hidden ? "hidden" : "visible"}>
                        <img src={video.thumbnail} />
                        <div className="videos__card__text | flex-column pad-xs gap-xxs">
                            <h4>{video.title}</h4>
                            {textToParagraphs(video.description || "")}
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className='videos__contact'>
            <Contact />
        </div>
    </div>
}

export default Videos;