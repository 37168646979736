import { State } from '../../../types/Utils';
import './Selector.scss';

interface SelectorProps {
    options: string[];
    activeIndex: State<number>;
}

const Selector = (props: SelectorProps) => {
    return (
        <div className="selector | flex-row gap-xs">
            {props.options.map((element, i) => {
                return <small
                    className="selector__option"
                    data-state={i == props.activeIndex.value ? "active" : "inactive"}
                    onClick={() => props.activeIndex.set(i)}
                >{element}</small>
            })}
        </div>
    )
}

export default Selector