import { ReactNode, useEffect, useState } from 'react';
import Header from '../../../components/header/Header';
import './WorkDetails.scss';
import { useParams } from 'react-router-dom';
import { Work, defaultImage } from '../../../types/Types';
import { getWorkFromId } from '../../../api/apiFunctions';
import Carousel, { ControlProps } from 'nuka-carousel';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { textToParagraphs } from '../../../utils/Functions';
import ImageGrid from '../../../components/imageGrid/ImageGrid';

export interface WorkDetailsProps {
    workId: string;
    substitute?: WorkDetailsSibstituteProps;
}

export interface WorkDetailsSibstituteProps {
    substitute: ReactNode;
    title: string;
    description: string;
}

const WorkDetails = (props: WorkDetailsProps) => {
    const workId = props.workId;
    const [work, setWork] = useState<Work>();
    const renderCenterLeftControls = ({ previousSlide, currentSlide }: ControlProps) => (
        <button
            className="slider-control-button"
            onClick={previousSlide}
            disabled={currentSlide === 0}
        >
            <FaArrowLeft />
        </button>
    );

    const renderCenterRightControls = ({
        nextSlide,
        currentSlide,
        slideCount,
        slidesToShow,
    }: ControlProps) => {
        console.log(currentSlide, slideCount, slidesToShow);
        return (
            <button
                className="slider-control-button flex-center"
                onClick={nextSlide}
                disabled={currentSlide + slidesToShow === slideCount}
            >
                <FaArrowRight />
            </button>
        );
    };

    //_________HOOKS_________
    useEffect(() => {
        if (workId) {
            getWorkFromId(workId, setWork);
        }
    }, [workId]);

    useEffect(() => {
        console.log(work);

    }, [work]);

    return (
        <div className="work-details | responsive-row gap-xl mgap-l">
            <div className="work-details__text | flex-column gap-xs">
                <h4 >{props.substitute?.title || work?.title}</h4>
                {props.substitute ? <p>{props.substitute.description}</p> : work && textToParagraphs(work.description)}
            </div>
            <div className="work-details__carousel-wrapper">
                { work && <ImageGrid images={work.images}></ImageGrid>}
            </div>
            {/* <img className="work-details__image | roundborders shadow" src={work?.thumbnail.url} alt="" /> */}
        </div>
    )
}

export default WorkDetails