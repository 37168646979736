import { useContext } from 'react';
import './Contact.scss';
import { GlobalDataContext } from '../../utils/GlobalValues';
import emailjs from 'emailjs-com';

const Contact = () => {
    const globalData = useContext(GlobalDataContext);

    function sendEmail(e: any) {
        e.preventDefault(); 
    
        emailjs.sendForm(globalData.value.emailjsServiceId, globalData.value.emailjsTemplateId, e.target, globalData.value.emailjsUserKey)
          .then((result) => {
              e.target.reset();
              alert(globalData.value.emailjsSuccessMessage);
          }, (error) => {
              console.log(error.text);
          });
      }

    return (
        <div className="contact | flex-column  gap-l mgap-s | vpad-l mvpad-xs bg-img text-shadow">
            <div className="contact__slogan hpad-xxxl "><p>{globalData.value.contactSlogan}</p></div>
            <div className="contact__media | responsive-row gap-xxxl ">
                <div className="contact__media__form | flex-column gap-xs">
                    <h4>{globalData.value.contactWebform}</h4>
                    <form action="" className="flex-column gap-xs" id="contact-form" onSubmit={sendEmail}>
                        <div className="responsive-row gap-xs">
                            <div className="input-group">
                                <small>{globalData.value.contactName}</small>
                                <input type="text" name="name"/>
                            </div>
                            <div className="input-group">
                                <small>{globalData.value.contactEmail}*</small>
                                <input type="text" required name="email"/>
                            </div>
                        </div>
                        <div className="input-group message">
                            <small>{globalData.value.contactMessage}</small>
                            <textarea name="message"/>
                        </div>

                        <input type="submit" value={globalData.value.contactSend} className="button-green shadow text-shadow semibold" style={{ textTransform: "uppercase" }}/>

                    </form>
                </div>
                <div className="contact__media__rrss__parent flex-column gap-m">
                    <div className="contact__media__rrss | flex-column gap-xs">
                        <h4>{globalData.value.contactSocialMedia}</h4>
                        <div className="flex-row | gap-xs">
                            <a href="https://instagram.com/efromero3d"><img src="img/ig.png" alt="" /></a>
                            <a href="https://es.linkedin.com/in/eduardo-fern%C3%A1ndez-romero-855656178"><img src="img/linkedin.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="contact__media__email | flex-column gap-xs">
                        <h4>{globalData.value.contactEmail}</h4>
                        <small>{globalData.value.contactEmailAddress}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact