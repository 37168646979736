
import { useContext, useEffect, useState } from 'react';
import Contact from '../../components/contact/Contact';
import Header from '../../components/header/Header';
import Gallery from './Gallery/Gallery';
import './Portfolio.scss';
import Selector from './Selector/Selector';
import { useParams } from 'react-router-dom';
import WorkDetails from './Work/WorkDetails';
import GoBack from './GoBack/GoBack';
import { GlobalDataContext } from '../../utils/GlobalValues';
import ScrollToTop from '../../utils/ScrollToTop';

interface PortfolioProps {
  defaultTypeOfWork?: string;
}

const Portfolio = (props: PortfolioProps) => {
  const { workId } = useParams();
  const [types, setTypes] = useState<string[]>(["Todos"]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(1);
  const globalData = useContext(GlobalDataContext);

  function createQuery() {
    return `{
  tipoDeTrabajoCollection {
          items{ name}
  }
}`}


  useEffect(() => {
    const query = createQuery();
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/hundvbs26llb/environments/master`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer HFJDmggU5lWtyvwL5erNeC3Jo0aP6wJcZnRowWXIJgI",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setTypes(["Todos"].concat(data.tipoDeTrabajoCollection.items.map((i: any) => i.name)));
      });
  }, []);

  return <div className="portfolio | flex-column center-x ">
    {!workId && <div className="portfolio__welcome | flex-column center-x gap-s mgap-xxxs | text-white textshadow bg-img11">
      <Header />
      <iframe className="shadow roundborders" src={globalData.value.portfolioFeaturedVideoUrl} frameBorder={0} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
    </div>}
    <div className="portfolio__gallery | flex-column center vpad-s gap-xxxs" id='portfolio-gallery'>
      <div className="portfolio__gallery__transitions" ></div>
      <h2 className='bold' style={{ textTransform: "uppercase" }}>{globalData.value.portfolioTitle}</h2>
      {workId ?
        <>
          <GoBack onClick={() => { setOpacity(1) }}></GoBack>
          <WorkDetails workId={workId}></WorkDetails>
        </> :
        <>
          <Selector options={types} activeIndex={{ value: activeIndex, set: setActiveIndex }} />
          <Gallery activeType={types[activeIndex]} />
        </>}
    </div>
    <Contact />
  </div>;
};

export default Portfolio;