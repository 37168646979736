import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface ScrollToTopProps {
    height?: number;
    elementId?: string;
    behaviour?: ScrollBehavior;
}

export default function ScrollToTop(props: ScrollToTopProps) {
    const { pathname } = useLocation();
    useEffect(() => {
        if (props.elementId){
            const element = document.getElementById(props.elementId)
            element?.scrollIntoView({block: "start", inline: "nearest" , behavior: props.behaviour || 'instant' as ScrollBehavior });
        } else if (props.height){
            window.scrollTo({ top: props.height * window.outerHeight, behavior: props.behaviour || 'instant' as ScrollBehavior })
        } else{
            window.scrollTo({ top: 0, behavior: props.behaviour || 'instant' as ScrollBehavior })
        }
    }, [pathname]);

    return null;
}