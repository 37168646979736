import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import './ImageGrid.scss';
import { MdOutlineZoomOutMap } from "react-icons/md";
import { Image } from '../../types/Types';
import { GlobalDataContext } from '../../utils/GlobalValues';

interface ImageGridProps {
    images: Image[];
    rowCount?: {
        bigScreen: number;
        mediumScreen: number;
        smallScreen: number;
    }
}

const ImageGrid = (props: ImageGridProps) => {
    //_________VARIABLES_________
    const images = props.images;
    const rowCountArray = props.rowCount || { bigScreen: 2, mediumScreen: 2, smallScreen: 1 };
    const imageWidth = 512;
    const [rowCount, setRowCount] = useState<number>(rowCountArray.bigScreen);
    const [imageRows, setImageRows] = useState<Image[][]>([]);
    const [fullscreenImage, setFullscreenImage] = useState<Image | null>(null);
    const mediumScreen = useMediaQuery('(min-width: 799.99px)');
    const bigScreen = useMediaQuery('(min-width: 1500px)');
    const globalData = useContext(GlobalDataContext);

    //____________FUNCTIONS__________


    function calculateRowCount(): number {
        if (mediumScreen) {
            if (bigScreen) {
                return rowCountArray.bigScreen;
            }
            return rowCountArray.mediumScreen;
        }
        return rowCountArray.smallScreen;
    }

    function getImageRowHeight(image: Image[]): number {
        let rowHeight = 0;

        image.forEach((image: Image) => {
            rowHeight += (imageWidth / image.width) * image.height;
        })

        return rowHeight;
    }

    function getImageRowsFromImages(images: Image[], numberOfRows: number) {
        let imageRows: Image[][] = [];
        for (let index = 0; index < numberOfRows; index++) {
            imageRows.push([]);
        }

        images.forEach((img: Image) => {
            imageRows.sort(function (a, b) {
                if (getImageRowHeight(a) > getImageRowHeight(b)) {
                    return 1;
                }
                if (getImageRowHeight(a) < getImageRowHeight(b)) {
                    return -1;
                }
                return 0;
            })[0].push(img);
        })

        return imageRows;
    }

    //_________HOOKS_________

    useEffect(() => {
        setRowCount(calculateRowCount);
    }, [mediumScreen, bigScreen]);

    useEffect(() => {
        setImageRows(getImageRowsFromImages(images, images.length < rowCount ? images.length : rowCount));
    }, [images, rowCount]);

    //_________COMPONENT_________
    return images.length > 0 ? (
        <div className="gallery">
            {imageRows &&
                <div className="gallery__wrapper | gap-s " style={{ gridTemplateColumns: `repeat(${rowCount}, 1fr)` }}>
                    {imageRows && imageRows.map((imageRow: Image[], i: number) => (
                        <div key={"row" + i} className='flex-column | gap-s'>
                            {imageRow.map((image: Image, j: number) => (
                                <div className='img-parent' onClick={() => { setFullscreenImage(image) }}>
                                    <img className='shadow roundborder' key={"img" + j} src={image.url + `?w=${imageWidth}&fm=webp&q=100`}></img>
                                    <div className="img-overlay | flex-column center gap-xs | roundborder text-white">
                                        <MdOutlineZoomOutMap style={{ height: "48px", color:"white" }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>}
            <div className="fullscreen" data-state={fullscreenImage ? "enabled" : "disabled"} onClick={() => { setFullscreenImage(null) }}>
                <img src={fullscreenImage?.url} className='shadow roundborder' />
            </div>
        </div>
    ) : <div className="white-bg | pad-xxl" >No hay contenido que mostrar (por el momento).</div>
}

export default ImageGrid