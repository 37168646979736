import { getUserLocales } from 'get-user-locale';

export function getUserLanguage() {
    const localStorageLanguage = localStorage.getItem('selectedLanguage');
    if (localStorageLanguage) {
        return localStorageLanguage;
    }

    const browserLanguages = getUserLocales();
    const foundLanguage = browserLanguages.find(browserLanguage => {
        return browserLanguage.startsWith("en") || browserLanguage.startsWith("es");
    });

    return foundLanguage ? foundLanguage.slice(0, 2) : 'en';
}

export function textToParagraphs(text: string) {
    return text.split("\n").filter((p: string) => p.length > 1).map((pa: any) => { return (<p>{pa}</p>) });
}