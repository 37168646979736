export interface Image {
    url: string;
    width: number;
    height: number;
}

export const defaultImage: Image = { url: "https://uning.es/wp-content/uploads/2016/08/ef3-placeholder-image.jpg", width: 10, height: 10 }

export interface Work {
    id: string;
    title: string;
    thumbnail: Image;
    position: number;
    category: string;
    hasOwnPage: boolean;
    images: Image[]
    description: string;
}

export interface WorkExperience {
    role: string;
    description: string[];
    date: string;
    place: string;
}

export interface Education {
    education: string;
    date: string;
    place: string;
}

export interface Skill {
    skill: string;
    level: number;
}

export interface Video {
    title: string;
    description: string;
    url: string;
    thumbnail: string;
    hidden: boolean;
    featured: boolean;
}