import { useContext, useEffect, useState } from "react";
import Contact from "../../components/contact/Contact";
import Header from "../../components/header/Header";
import './AboutMe.scss';
import { GlobalDataContext } from "../../utils/GlobalValues";
import { Education, Skill, WorkExperience } from "../../types/Types";
import { getAllEducation, getAllSkills, getAllWorkExperience } from "../../api/apiFunctions";


const AboutMe = () => {
  const globalData = useContext(GlobalDataContext);
  const [workExperience, setWorkExperience] = useState<WorkExperience[]>();
  const [educationEntries, setEducationEntries] = useState<Education[]>();
  const [skillEntries, setSkillEntries] = useState<Skill[]>();

  useEffect(() => {
    getAllWorkExperience(setWorkExperience);
    getAllEducation(setEducationEntries);
    getAllSkills(setSkillEntries);
  }, []);

  return (<div className="about | flex-column" >
    <div className="about__intro | flex-column gap-xxxl mgap-xs | text-white textshadow bg-img7">
      <Header />
      <div className="responsive-row nw gap-xxl ">
        <img className="roundborders" src={globalData.value.aboutMePictureUrl} alt="" />
        <div className="about__intro__bio">
          <div className="flex-column gap-m">
            <h2>{globalData.value.aboutmeTitle}</h2>
            <div className="flex-column gap-xxs">
              {globalData.value.aboutmeDescription.split("\n").map(p => <p>{p}</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="about__experience | flex-column center vpad-m gap-m mgap-xxxs">
      <h2 className="bold vpad-xxxs">{globalData.value.aboutmeExperience}</h2>
      <div className="flex-column pad-m">
        {workExperience?.map((e, i) =>
          <div className="about__experience__entry | responsive-row gap-xl mgap-xxxs vpad-xxs2 mvpad-xs">
            <div className="data flex-column gap-xxs">
              <h4>{e.role}</h4>
              <h5>{e.place}</h5>
              <small>{e.date}</small>
            </div>
            <div className="desc vpad-m flex-column gap-xxs">
              {e.description.map(d => <small>- {d}</small>)}
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="about__education | flex-column center vpad-m gap-m mgap-xxxs">
      <h2 className="bold vpad-xxxs">HABILIDADES Y FORMACIÓN</h2>
      <div className="responsive-row | gap-xl vpad-m">
        <div className="about__education__skills | flex-column roundborders shadow gap-m pad-m">
          <h5 className="center">HABILIDADES</h5>
          <div className="flex-column gap-xs">
            {skillEntries?.map((s, i) => <div className="flex-column gap-xxs3"><small>{s.skill}</small><div className="skill-bar roundborders"><div style={{ width: s.level + "%" }}>{globalData.value.aboutMeShowSkillNumber && <span>{s.level}</span>}</div></div></div>)}
          </div>
        </div>
        <div className="flex-column">
          {educationEntries?.map((e, i) => <div className="data about__education__entry | flex-column gap-xxs vpad-l">
            <h4>{e.education}</h4>
            <h5>{e.place}</h5>
            <small>{e.date}</small>
            {/* <ul className="vpad-xxs">
          <li><small>{e.description}</small></li>
        </ul> */}
          </div>
          )}
        </div></div>
    </div>
    {/* <div className="about__education | flex-column center vpad-m gap-m">
      <h3>HABILIDADES Y FORMACIÓN</h3>
      <div className="responsive-row | gap-xl">
        <div className="about__education__skills | flex-column roundborders shadow gap-m pad-m">
          <h5 className="center">HABILIDADES</h5>
          <div className="flex-column gap-xs">
            {skillEntries.map((s, i) => <div className="flex-column gap-xxs3"><small>{s.skill}</small><div className="skill-bar roundborders"><div style={{ width: s.level + "%" }}>{s.level}</div></div></div>)}
          </div>
        </div>
        <div className="flex-column gap-m">
          <h2>Sobre mí</h2>
          <div className="flex-column gap-s">
            <p>Mi nombre es Eduardo Fernández.</p>
            <p>Soy un apasionado de la animación y los videojuegos y tengo un alto dominio de las técnicas de modelado tanto en superficies duras como en escultura.</p>
            <p>Manejo varios estilos, desde estilizado a realista, así como diferentes partes del pipeline desde texturizado a ajustes para render. Amante de la animación y los videojuegos.</p>
          </div>
        </div>
      </div>
    </div> */}
    <Contact />
  </div >);
};

export default AboutMe;