import { Link } from "react-router-dom";
import Contact from "../../components/contact/Contact";
import Header from "../../components/header/Header";
import "./Home.scss";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import { Image, defaultImage } from '../../types/Types';
import { GlobalDataContext } from "../../utils/GlobalValues";
import { textToParagraphs } from "../../utils/Functions";

const Home = () => {
  const imageWrapperRef = useRef<any>();
  const [animationLoop, setAnimationLoop] = useState<any>(null);
  const [imageOneSrc, setImageOneSrc] = useState<string>("");
  const [imageTwoSrc, setImageTwoSrc] = useState<string>("");
  const [images, setImages] = useState<Image[]>([defaultImage, defaultImage]);
  const globalData = useContext(GlobalDataContext);

  useEffect(() => {
    setImages(globalData.value.homePortfolioImages)
    console.log(globalData)
  }, [globalData])


  useEffect(() => {
    if (animationLoop){
      clearInterval(animationLoop);
    }

    const imageOne = Array.from(imageWrapperRef.current.children)[0] as HTMLDivElement;
    const imageTwo = Array.from(imageWrapperRef.current.children)[1] as HTMLDivElement;

    if (!imageOne || !imageTwo){
      return;
    }

      // 0ms: inicio
      // Imagen 1 en posición y visible
      var nextIndex = 0;

      setImageOneSrc(images[nextIndex % images.length]?.url + "?h=350&fm=webp&q=100");
      imageOne.style.opacity = "1";
      imageOne.style.left = "0";

      // Imagen 2 a la derecha y oculta
      imageTwo.style.opacity = "0";
      imageTwo.style.left = "20%";
      nextIndex += 1;
      setImageTwoSrc(images[nextIndex % images.length]?.url + "?h=350&fm=webp&q=100");

      const animation = () => {
        //2400 ms: siguiente posición
        setTimeout(() => {
          //Imagen 1 en posición (la mantiene) y pasa a oculta
          imageOne.style.opacity = "0";

          //Imagen 2 pasa a en posición y pasa a visible
          imageTwo.style.left = "0";
          imageTwo.style.opacity = "1";
        }, 2400);

        //3500ms: cambio de src y posición en la imagen oculta (la 1)
        setTimeout(() => {
          nextIndex += 1;
          setImageOneSrc(images[nextIndex % images.length]?.url + "?h=350&fm=webp&q=100");
          imageOne.style.left = "20%";
        }, 3500);

        //4800ms: siguiente posición (inicial)
        setTimeout(() => {
          //Imagen 1 pasa a en posición y pasa a visible
          imageOne.style.opacity = "1";
          imageOne.style.left = "0";

          //Imagen 2 en posición (la mantiene) y pasa a oculta
          imageTwo.style.opacity = "0";
        }, 4800);

        //4900ms: cambio de src y posición en la imagen oculta (la 1)
        setTimeout(() => {
          nextIndex += 1;
          setImageTwoSrc(images[nextIndex % images.length]?.url + "?h=350&fm=webp&q=100");
          imageTwo.style.left = "20%";
        }, 5900);
      }

      animation();
      setAnimationLoop(setInterval(animation, 4800));
  }, [images, imageWrapperRef])



  return (
    <div className="home | flex-column">
      <div className="home__welcome  | flex-column center-x | text-white textshadow">
        {globalData.value.homeBgVideoUrl && <video id="background-video" autoPlay loop muted>
          <source src={globalData.value.homeBgVideoUrl} type="video/mp4" />
        </video>}
        <Header></Header>
        <div className="home__welcome__text | flex-column gap-m center">
          <div className="|flex-column gap-xxxs">
            <h1 className="| center">{globalData.value.homeWelcomeTitle}</h1>
          </div>
          {textToParagraphs(globalData.value.homeWelcomeDescription)}
        </div>
      </div>
      <div className="home__portfolio | responsive-row center gap-xxxxl mgap-xl | vpad-xl mvpad-xl">
        <div className="home__portfolio__text | flex-column center-y gap-m">
          <div className="home__portfolio__text__group | flex-column center-y gap-xs">
            <h4 className="bold">{globalData.value.homePortfolioTitle}</h4>
            <div className="flex-column gap-xxs">{textToParagraphs(globalData.value.homePortfolioDescription)}</div>
          </div>
          <Link className="home__portfolio__text__button | button shadow" to="/portfolio">{globalData.value.homePortfolioButton} <MdOutlineDoubleArrow /></Link>
        </div>
        <div className="home__portfolio__image" ref={imageWrapperRef}>
          <div className="home__portfolio__image__wrapper">
            <img className="roundborder shadow" src={imageOneSrc}></img>
          </div>
          <div className="home__portfolio__image__wrapper">
            <img className="roundborder shadow" src={imageTwoSrc}></img>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Home;
