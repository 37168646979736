import { Link } from 'react-router-dom';
import { FaChevronCircleLeft } from "react-icons/fa";
import './GoBack.scss';
import { useContext } from 'react';
import { GlobalDataContext } from '../../../utils/GlobalValues';


interface GoBackProps {
    onClick: () => void;
}
const GoBack = (props: GoBackProps) => {
    const globalData = useContext(GlobalDataContext);
    return (
        <div className="goback | flex-row vpad-xxs gap-xl vpad-xl ">
            <Link className="goback__link | button " to={"/portfolio/gallery"} onClick={props.onClick}><FaChevronCircleLeft /> {globalData.value.portfolioGoback}</Link>
        </div>
    )
}

export default GoBack