import "./App.scss";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import AboutMe from "./pages/AboutMe/AboutMe";
import { NavBar } from "./components/navBar/NavBar";
import ScrollToTop from "./utils/ScrollToTop";
import Videos from "./pages/Videos/Videos";
import { useContext, useEffect } from "react";
import { GlobalDataContext, useGlobalData } from "./utils/GlobalValues";
import { getGeneralData } from "./api/apiFunctions";
const ROUTES = [
  {
    path: "/",
    component: <Home />,
    activePage: "home",
  },
  {
    path: "/portfolio",
    component: <Portfolio />,
    activePage: "portfolio",
  },
  {
    path: "/portfolio/gallery",
    component: <Portfolio />,
    scrollId:"portfolio-gallery",
    activePage: "portfolio",
  },
  {
    path: "/about",
    component: <AboutMe />,
    activePage: "about",
  },
  {
    path: "/videos",
    component: <Videos />,
    activePage: "videos",
  },
  {
    path: "/portfolio/:workId",
    component: <Portfolio />,
    scrollBehaviour: "smooth" as ScrollBehavior,
    activePage: "portfolio",
  },
];


function App() {

  return (
    <GlobalDataContext.Provider value={useGlobalData()}>
      <SetContext>
        <BrowserRouter>
          <Routes>
            {ROUTES.map((route, i) => (
              <Route path={route.path} key={i} element={<><NavBar activePage={route.activePage} /><ScrollToTop height={route.scrollId ? undefined : 0 } elementId={route.scrollId} behaviour={route.scrollBehaviour}/>{route.component}</>} />
            ))}
          </Routes>
        </BrowserRouter>
      </SetContext>
    </GlobalDataContext.Provider>
  );
}

export default App;

const SetContext = (props: any) => {

  const context = useContext(GlobalDataContext);

  useEffect(() => {
    getGeneralData(context.set);
  }, [])

  return <>{props.children}</>

}
